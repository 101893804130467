<template>
  <div class="more-analyse-overseas" v-loading="loading" ref="dialog">
    <div class="main-top" >
      <el-row class="mb20" type="flex" justify="space-between" align="middle">
        <el-col >
          <div class="main-top-text" >
            {{$t('h.song.analysis')}} <span> [{{songData.track_name}}]</span> 
            <i
              class="play-btn"
              :class="isAudioPlay && songData.mp3Url === mp3Url ? 'icon-pause1' : 'icon-play1'"
              @click="playMusic(songData)"
            >
            </i>
          </div>
        </el-col>
        <el-col :span="2.5">
          <el-button size="small" @click="closeDialog()">
            {{$t('h.song.return')}}
          </el-button>
        </el-col>
      </el-row>
    </div>

    <el-tabs v-model="activeTab" @tab-click="clickTab" :lazy="true">
      <!-- PDM 打分 -->
      <el-tab-pane label="PDM Score" name="pdm_score">
        <pdm-score :song-data="songData"></pdm-score>
      </el-tab-pane>

      <!-- 歌单分析 -->
      <el-tab-pane v-if="songData.permission.playlist"  label="Playlist Analysis" name="playlist-recommendation">
        <playlist-analysis :models="modelList" :pdm-level="songData.multi_layer_result[0].level" :artist-region="songData.region" :platform-data="platformData" :is-gospel="isGospel"></playlist-analysis>
      </el-tab-pane>

      <!-- 受众分析 -->
      <el-tab-pane v-if="songData.permission.audience_profile" label="Audience Profile Prediction" name="audience-profile-prediction">
        <audience-profile-prediction v-if="audienceData[0]" :models="modelList" :pdm-level="songData.multi_layer_result[0].level" :artist-region="songData.region" :region-data="audienceData"></audience-profile-prediction>
        <my-empty v-else :empty-text="$t('h.song.emptyText2')"></my-empty>
      </el-tab-pane>

      <!-- 短视频宣推 -->
      <el-tab-pane v-if="songData.permission.short_video" label="Short-video Promotion Analysis" name="promote-suggestions">
        <short-video-promotion 
          v-if="!promotionErrMsg"
          :is-audio-play="isAudioPlay" 
          :version="promotionVersion"
          :song-data="songData" 
          :promotion-obj="promotionObj"
          @handleMusic="handleMusic"
        ></short-video-promotion>
        <my-empty v-else :empty-text="promotionErrMsg"></my-empty>
      </el-tab-pane>
    </el-tabs>

    <audio-player 
      class="audio-player" 
      ref="audioPlayer" 
      :mp3-name="mp3Name" 
      :mp3-url="mp3Url" 
      @updateAudioStatus="updateAudioStatus">
    </audio-player>
  </div>
</template>

<script>
import PdmScore from '../common/__pdm-score'
import AudienceProfilePrediction from '../common/__audience-profile-prediction'
import PlaylistAnalysis from '../common/__playlist-analysis'
import ShortVideoPromotion from '../common/__short-video-promotion'

export default {
  components: {
    PdmScore,
    AudienceProfilePrediction,
    PlaylistAnalysis,
    ShortVideoPromotion
  },

  props: {
    showDialog: {
      type: Boolean,
    },
    songData: {
      default: ''
    },
    modelList:{
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      dialogTableVisible: false,
      loading: false,
      activeTab: "pdm_score",
      audioPlayer:'',
      isAudioPlay:false,
      mp3Name: '',
      mp3Url: '',
      audienceData: [],
      platformData: {},
      promotionObj: {},
      promotionErrMsg: '',
      promotionVersion: '',
      isGospel: false
    }
  },

  computed: {
    token: function () {
      return localStorage.getItem("token");
    }
  },

  watch: {
    showDialog (val) {
      if (val) {
        this.dialogTableVisible = val
      } else {
        this.activeTab = "pdm_score"
      }
    }
  },

  methods: {
    async clickTab (tab) {
      switch (tab.name) {
        case 'playlist-recommendation':
          this.getPlaylistData()
          break
        case 'audience-profile-prediction':
        this.getAudienceProfile()
          break
        case 'promote-suggestions':
          this.getSongPromotion()
          break
        default:
          break
      }
    },
    
    async getAudienceProfile (){
      this.loading = true
      const result = await this.$store.dispatch("getScore", {
        token: this.token,
        song_id: this.songData.id,
        result_type: "audience_profile"
      })
      if (result.code === 2000) {
        const report = result.result
        if (report.region_analysis){
          this.audienceData = report.region_analysis[0]
        } else {
          this.audienceData = []
        }
      } else {
        this.$message({
          message: result.msg,
          type: "error"
        })
      }
      this.loading = false
    },

    // 获取宣推建议数据
    async getSongPromotion(){
      this.loading = true
      const res = await this.$store.dispatch("getSongPromotion", {
        token: this.token,
        song_id: this.songData.id
      })
      if (res.code ==2000){
        if (res.version == 'song_promotion_v2') {
          this.promotionVersion = 'v2'
        }
        if (res.version == 'song_promotion') {
          this.promotionVersion = 'v1'
        }
        this.promotionObj = res.promotion_data.clips[0]
      } else {
        this.promotionErrMsg = res.msg
      }
      this.loading = false
    },

    async getPlaylistData(){
      this.loading = true
      const res = await this.$store.dispatch("getScore", {
        token: this.token,
        song_id: this.songData.id,
        result_type: "playlist"
      })
      if (res.code === 2000) {
        this.platformData = res.result.playlist
        this.isGospel = res.tags && res.tags.is_gospel
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        })
      }
      this.loading = false
    },

    handleMusic (obj) {
      if (obj.status === 'pause') {
        this.$refs.audioPlayer.pauseMusic()
      } else {
        this.playMusic({
          mp3Url: obj.url,
          name: ''
        })
      }
    },

    closeDialog() {
      this.$emit("closeDialog");
      // 关闭弹框,清空数据
      Object.assign(this.$data, this.$options.data());
    },
    
    playMusic (obj) {
      this.mp3Name = obj.name
      this.mp3Url = obj.mp3Url
      if (this.isAudioPlay) {
        this.$refs.audioPlayer.pauseMusic()
      } else {
        this.$refs.audioPlayer.playMusic()
      }
    },

    updateAudioStatus (status) {
      this.isAudioPlay = status
    }
  }
}
</script>


<style lang="stylus" scoped>
.more-analyse-overseas {
  height: 100%;
  display: flex;
  flex-direction: column; 
  user-select: text;

  .main-top-text {
    font-size: 18px;
    font-weight: 600;
    color: #1a1a1a;
    
    span {
      color:#ff8c36;
    }

    .play-btn {
      margin-left: 15px;
      font-size: 24px;
      color: #aaa;
      position: relative;
      top: 2px;
      cursor: pointer;
    }
  }
  .pdm-score{
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .title {
    color: #ff8c36;
    font-size: 20px;
    margin: 20px 0;
    padding-left: 15px;
    border-left: 5px solid #ff8c36;
    line-height: 30px;
    font-weight: 600;
  }
  .audio-player {
    display: none;
  }
}
::v-deep .el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
::v-deep .el-tabs__content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
::v-deep .el-tab-pane {
  flex: 1;
  overflow: scroll;
}
</style>