<template>
  <div class="v-pdm-score">
    <table class="song-table tac">
      <tr>
        <th class="tal col-30">Track Name</th>
        <th v-if="userType === 'afrotunes'" class="col-15">Artist</th>
        <th v-if="userType === 'afrotunes'">Artist Country</th>
        <th v-for="(model, index) in songData.multi_layer_result" :key="index">PDM Score - {{ modelList[model.model_name] || model.model_name }}</th>
      </tr> 
      <tr>
        <td class="col-30">
          <p class="track-name">{{songData.track_name}}</p>
        </td>
        <td v-if="userType === 'afrotunes'">
          <p class="artist-name">{{songData.artist_name || '--'}}</p>
        </td>
        <td v-if="userType === 'afrotunes'">{{regionDict[songData.region] || '--'}}</td>
        <td class="score" v-for="(model, index) in songData.multi_layer_result" :key="index">{{ model.score }}</td>
      </tr>
    </table>
    <p class="second-title">The ratings represent the hit potential to get into the related music charts: </p>
    <table class="song-table">
      <tr>
        <th>Score Range</th>
        <th>Definition</th>
        <th>Example</th>
      </tr>
      <tr>
        <td class="section">>90</td>
        <td>Among all releases of the market, possibility of making into the music chart for tracks scored above 90 is 20 times higher than any other releases.</td>
        <td>If we consider <b>1 of 100 random tracks</b>  promoted will be in the chart and be a hit, it would be <b>20</b> making into the chart of <b>100 tracks scored above 90</b>.</td>
      </tr>
      <tr>
        <td class="section">85~90</td>
        <td>Among all releases of the market, possibility of making into the music chart for tracks scored between 85 to 90 is 10 times higher than any other releases.</td>
        <td>If we consider <b>1 of 100 random tracks</b> promoted will be in the chart and be a hit, it would be <b>10</b> making into the chart of <b>100 tracks scored between 85 to 90</b>.</td>
      </tr>
      <tr>
        <td class="section">75~85</td>
        <td>We recall a large number of high-potential releases at the expense of accuracy.70% of the releases that will enter the chart have a score of 75 or higher. </td>
        <td>We consider tracks scored above 75 represent <b>approximately the top 20%</b> of all releases in the market, covering almost all of the tracks that will get into the music chart.</td>
      </tr>
      <tr>
        <td class="section">&lt;75</td>
        <td>The remaining songs are unlikely to chart.</td>
        <td></td>
      </tr>
    </table>
    <p class="second-title">PDM score for content strategies analysis:</p>
    <analysis></analysis>
  </div>
</template>

<script>
import Analysis from './__analysis.vue' 

export default {
  components: {
    Analysis
  },
  
  props: {
    songData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data () {
    return {
      modelList: {
        'na_pop_v1': 'North America',
        'na_pop_v2': 'North America',
        'na_edm_v1': 'EDM',
        'af_east_tz_v1': 'Tanzania',
        'af_west_ng_v1': 'Nigeria'
      },
      regionDict:{
        'GH':'Ghana',
        'KE':'Kenya',
        'NG':'Nigeria',
        'ZM':'Zambia',
        'UG':'Uganda'
      }
    }
  },

  computed: {
    userType () {
      return this.$store.state.user.usertype
    }
  }
}
</script>

<style lang="stylus">
.v-pdm-score {
  font-size: 16px;
  .song-table {
    width: 100%;
    border-collapse:collapse;
    border-radius: 6px;
    overflow: hidden;
    th {
      height: 40px;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 600;
      font-size: 14px;
      padding: 10px 20px;
    }
    td {
      height: 66px;
      padding: 10px 25px;
      line-height: 28px;
    }
    tr:nth-child(2n) {
      background-color: #fbfbfb;
    }
    tr:nth-child(2n+1) {
      background-color: #f3f3f3;
    }
    .report-time {
      position: relative;

      .pdm-stamp {
        position: absolute;
        opacity: 0.4;
        width: 80px;
        top: 2px;
        right: -15px;
        transform: rotate(-15deg);
      }
    }
    .track-name {
      width: 100%;
      overflow: hidden; 
      text-overflow: ellipsis; 
      white-space: nowrap;
      line-height: 66px;
      color: #000;
      text-align: left;
    }
    .col-30 {
      width: 30%;
    }
    .col-15 {
      width: 15%;
    }
    .artist-name {
      font-size: 17px;
      line-height: 66px;
      overflow: hidden; 
      text-overflow: ellipsis; 
      white-space: nowrap;
      text-align: center;
    }
    .section{
      color: #ff8c36;
      font-size: 20px;
      font-weight:600;
      text-align: center;
      width: 160px;
      min-height: 90px;
    }
    .score {
      color: #ff8c36;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .second-title {
    font-size: 14px;
    line-height: 28px;
    margin: 10px 0;
  }
}
</style>