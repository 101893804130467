<template>
  <div class="more-analyse" v-loading="loading" ref="dialog">
    <div class="main-top">
      <el-row class="mb10" type="flex" justify="space-between" align="middle">
        <el-col>
          <div class="main-top-text" >
            {{$t('h.song.analysis')}} <span> [{{songData.track_name}}]</span>
            <i
              class="play-btn"
              :class="isAudioPlay && songData.mp3Url === mp3Url ? 'icon-pause' : 'icon-play'"
              @click="playMusic(songData)"
            >
            </i>
          </div>
        </el-col>
        <el-col :span="2.5" >
          <el-button size="small"  @click="closeDialog()">
            {{$t('h.song.return')}}
          </el-button>
        </el-col>
      </el-row>
    </div>

    <el-tabs v-model="activeTab" @tab-click="clickTab" :lazy="true">
      <el-tab-pane :label="$t('h.song.dialogTab1')" name="platform_score">
        <div class="card-box">
          <div class="result-card">
            <div class="flex-box">
              <div class="platform-card">
                <div class="platform-level">{{ getPlatformInfo('level', 'qq') }}</div>
                <div class="white-card">
                  <div class="platform-score">
                    <p class="title">{{$t('h.song.score')}}</p>
                    <p class="score">{{ getPlatformInfo('score', 'qq') }}</p>
                  </div>
                  <div class="platform-name">QQ音乐</div>
                </div>
              </div>

              <div v-if="songData.multi_layer_result && songData.multi_layer_result.length > 1" class="platform-card ml20">
                <div class="platform-level">{{ getPlatformInfo('level', 'kg') }}</div>
                <div class="white-card">
                  <div class="platform-score">
                    <p class="title">{{$t('h.song.score')}}</p>
                    <p class="score">{{ getPlatformInfo('score', 'kg') }}</p>
                  </div>
                  <div class="platform-name">酷狗音乐</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="level-tips">
          <p>等级说明：（PDM打分由TME曲库海量上亿数据分析得出，评级代表预测歌曲在市场上对应的播放量表现，与音乐性的好坏无关。）</p>
          <p>1、S级表示高潜作品，一定量的推广前提下，13%概率达到日播100w。市场一般水平歌曲相应概率为3%。</p>
          <p>2、A级表示优质作品，一定量的推广前提下，6%概率达到日播100w。市场一般水平歌曲相应概率为3%。</p>
          <p>3、B级歌曲为较优质作品，可覆盖80%的市面爆款歌曲。</p>
          <p>4、C级歌曲市场潜力相对较低。</p>
        </div>
      </el-tab-pane>

      <el-tab-pane
        v-if="$route.path.includes('assess')"
        :label="$t('h.song.dialogTab2')"
        name="audiences_analyse"
      >
        <div v-if="chartsData.age && chartsData.age.length" class="chart-box">
          <div class="dialog_gender">
            <p>{{$t('h.song.genderRatio')}}</p>
            <div ref="gender_chart" id="gender"></div>
            <div class="slash"></div>
            <div class="gender_male">
              <i class="point male"></i>
              {{$t('h.song.male')}}
            </div>
            <div class="gender_female">
              <i class="point female"></i> 
              {{$t('h.song.female')}}
            </div>
          </div>
          <div class="dialog_city">
            <p class="age_text">{{$t('h.song.cityDistribution')}}</p>
            <div ref="city_chart" id="city"></div>
          </div>
          <div class="dialog_age">
            <div class="age_text">{{$t('h.song.ageDistribution')}}</div>
            <div ref="age_chart" id="age"></div>
          </div>
        </div>
        <my-empty v-else :empty-text="$t('h.song.emptyText')"></my-empty>
      </el-tab-pane>

      <el-tab-pane :label="$route.path.includes('assess') ? $t('h.song.dialogTab3') :$t('h.song.dialogTab4')" name="singer_match">
        <div class="singer-pool">
          <p class="mr10">{{$t('h.singerPool.singerPool')}} : </p>
          <el-radio-group class="singer-pool-group" v-model="singerPool" @change="selectSingerPool">
            <el-radio v-for="singerPool in singerPoolList" :key="singerPool.singer_pool_id" :label="singerPool.singer_pool_id">{{ singerPool.singer_pool_name }}</el-radio>
          </el-radio-group>
        </div>
        <el-table
          v-show="!isSpecialPool"
          :data="tableData"
          stripe
          :header-cell-style="{
            color: '#aaa',
            background: '#FAFAFC',
          }"
          :height="tableHeight + 'px'"
        >
          <my-empty slot="empty" empty-text="在歌手池中未匹配到合适歌手"></my-empty>
          <el-table-column show-overflow-tooltip prop="singer_name" :label="$t('h.song.singer')">
          </el-table-column>
          <el-table-column prop="song_name">
            <span slot="header">
                {{$t('h.song.referenceSong')}} 
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content"> {{$t('h.song.referenceMsg')}} </div>
                <i class="icon-attention1"></i>
              </el-tooltip>
            </span>
          </el-table-column>

          <el-table-column :label=" $t('h.song.audition')" :min-width="isSpecialPool ? 120 : null">
            <template slot-scope="scope">
              <div v-if="scope.row.song_link" :id="`pbox${scope.$index}`" class="pbox">
                <i 
                  class="player"
                  @click="playMusic(scope)"
                  :class="isAudioPlay && scope.row.song_link === mp3Url ? 'icon-pause' : 'icon-play'"
                ></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="!isSpecialPool" align="center">
            <span slot="header">
                {{$t('h.song.feedback')}}
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">
                  {{$t('h.song.feedbackMsg')}}<br />{{$t('h.song.feedbackMsg1')}}
                </div>
                <i class="icon-attention1"></i>
              </el-tooltip>
            </span>
            <div slot-scope="scope">
              <a
                title="like"
                style="cursor: pointer"
                @click="choseLike(scope.row, 'like')"
                class="icon-good"
                :class="{ like: scope.row.feedback == 'like' }"
              ></a>
              &nbsp;
              <a
                title="dislike"
                @click="choseLike(scope.row, 'dislike')"
                style="cursor: pointer; margin-left: 30px"
                class="icon-bad"
                :class="{ dislike: scope.row.feedback == 'dislike' }"
              ></a>
            </div>
          </el-table-column>
        </el-table>
        <el-table
          v-show="isSpecialPool"
          :data="tableData"
          stripe
          :header-cell-style="{
            color: '#aaa',
            background: '#FAFAFC',
          }"
          :height="tableHeight + 'px'"
        >
          <my-empty slot="empty" empty-text="在歌手池中未匹配到合适歌手"></my-empty>
          <el-table-column show-overflow-tooltip prop="singer_name" :label="$t('h.song.singer')">
          </el-table-column>
          <el-table-column prop="song_name">
            <span slot="header">
                {{$t('h.song.referenceSong')}} 
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content"> {{$t('h.song.referenceMsg')}} </div>
                <i class="icon-attention1"></i>
              </el-tooltip>
            </span>
          </el-table-column>
          <el-table-column :label=" $t('h.song.audition')" :min-width="isSpecialPool ? 120 : null">
            <template slot-scope="scope">
              <div v-if="scope.row.song_link" :id="`pbox${scope.$index}`" class="pbox">
                <i 
                  class="player"
                  @click="playMusic(scope)"
                  :class="isAudioPlay && scope.row.song_link === mp3Url ? 'icon-pause' : 'icon-play'"
                ></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('h.song.homepage')">
            <template slot-scope="scope">
              <a :href="scope.row.personal_link.personal_link" target="_blank" class="primary pointer mr10">全民K歌</a>
              <a v-if="scope.row.personal_link.douyin_link && scope.row.personal_link.douyin_link.includes('douyin')" target="_blank" :href="scope.row.personal_link.douyin_link" class="primary pointer">抖音</a> 
            </template>
          </el-table-column>
          <el-table-column  :label="$t('h.song.quote')">
            <template slot-scope="scope">
              <span>{{scope.row.clip_cost}}元 / {{scope.row.full_song_cost}}元</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('h.song.contact')">
            <template slot-scope="scope">
              <span class="primary pointer" @click="copyContactInfo(scope.row.contact_info)">点击复制</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="tips">* {{$t('h.song.tips')}}</div>
      </el-tab-pane>

      <el-tab-pane v-if="$route.path.includes('demo')" :label="$t('h.song.dialogTab5')" name="similar_hotsong">
        <div class="song-pool">
          <p>{{$t('h.song.songPool')}}：</p>
          <div>
            <el-select ref="similar_hotsong_select" v-model="songPool" @change="getSimilarHotSong(1)">
              <el-option v-for="song in songPoolList" :key="song.id" :label="song.song_pool_name" :value="song.id"></el-option>
            </el-select>
            <p class="pool-tips">{{$t('h.song.songPoolTips')}}</p>
          </div>
        </div>
        <el-table
          :data="hotSongList"
          stripe
          :header-cell-style="{
            color: '#aaa',
            background: '#FAFAFC',
          }"
          :height="(tableHeight-40) + 'px'"
          style="width: 100%"
        >
          <my-empty slot="empty" empty-text="暂无相似爆款数据"></my-empty>
          <el-table-column prop="song_name" :label="$t('h.song.song')">
            <template slot-scope="scope">
              <p class="can-select">{{ scope.row.song_name }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="singer_name" :label="$t('h.song.singer')">
            <template slot-scope="scope">
              <p class="can-select">{{ scope.row.singer_name }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="score" :label="$t('h.song.similarity')"></el-table-column>
          <el-table-column :label="$t('h.song.audition')">
            <template slot-scope="scope">
              <div v-if="scope.row.song_link" :id="`sbox${scope.$index}`" class="sbox">
                <i 
                  class="player"
                  @click="playMusic(scope)"
                  :class="isAudioPlay && scope.row.song_link === mp3Url ? 'icon-pause' : 'icon-play'"
                ></i> 
              </div>
            </template>
          </el-table-column>
        </el-table>
        <p class="tips">* {{$t('h.song.similarityTips')}}</p>
      </el-tab-pane>

      <el-tab-pane v-if="$route.path.includes('assess')" :label="$t('h.song.dialogTab6')" name="song_promotion">
        <div v-if="this.clips" class="song-promotion">
          <div class="song-promotion-title">{{$t('h.song.promoteTitle1')}}</div>
          <div class="song-promotion-songclips">
            <el-radio-group v-if="clips.length>1" v-model="activeClip">
              <el-radio-button v-for='(clip,index) in clips'
                :key='clip.clip_id'
                :label="index" >{{$t('h.song.footage')}}{{index+1}}
              </el-radio-button>
            </el-radio-group>  
          </div>
          <div class="song-promotion-songplayer" v-for='(clip,index) in clips'  :key='index'>
            <fragment-player 
              v-if="activeClip === index" 
              :startTime="promotionVersion == 'song_promotion_v2'?clips[index].point[0]:clips[index].starting_timestamp" 
              :endTime="promotionVersion == 'song_promotion_v2'?clips[index].point[1]:clips[index].starting_timestamp+30"
              :mp3Url="songData.mp3Url"
              @pausePlay="handlePlay"
              ref ="fragment"
            >
            </fragment-player>
          </div>

          <div class="song-promotion-title">{{$t('h.song.promoteTitle2')}}</div>
          <div v-if="promotionVersion == 'song_promotion_v2' && this.promotions[0]">
            <el-form>
              <el-form-item label="相似歌曲：" class="search-level">
                <el-select ref="song_promotion_select" v-model="promotion">
                  <el-option 
                    v-for="(item,key) in promotions" 
                    :key="key" 
                    :label="(item.song_name+'(相似度：'+(item.similarity.toFixed(2)*100)+'%)')" 
                    :value="key"
                  >
                  </el-option>
                </el-select>
                <i 
                class="player ml10"
                @click="playMusic(promotions[promotion])"
                :class="isAudioPlay && promotions[promotion].song_link === mp3Url ? 'icon-pause' : 'icon-play'"
              ></i> 
              </el-form-item>
              <el-form-item label="Top3高赞短视频："></el-form-item>
            </el-form>
            <div class="song-promotion-videos-v2" >
              <div 
                v-for="(video,index) in videos_info" 
                :key="video.video_id"  
                class="video-box-v2"
              >
                <div class="mb10">
                  <el-button class="video-button-first" :id="video.video_id+'ori'" @click="handleVideoType(index,video.url,video.video_id,'ori')" >原 视 频</el-button>
                  <el-button class="video-button-second video-button-active" :id="video.video_id+'syn'" @click="handleVideoType(index,video.video_clip_link,video.video_id,'syn')" style="margin-left:0px">合成视频</el-button>
                </div>
                <video
                :src="video.video_clip_link" 
                @play="handlePlay (index)"
                class="song-promotion-video-v2" 
                controls="controls" 
                >
                您的浏览器不支持 video 标签。
                </video>
                <div class="video-info">
                  <i class="fs22 mr10 light-gray" :class="`icon-level${index + 1}`"></i>
                  <div>
                    <p class="video-title">创作者：{{video.video_author}}</p>
                    <p class="video-title mt5">视频类型：{{video.tag}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="empty-div"  v-else-if="promotionVersion == 'song_promotion_v2'">
            <my-empty :empty-text="$t('h.song.emptyText3')"></my-empty>
          </div>
          <div v-else>
            <div class="song-promotion-videos" >
              <div 
                v-for="(video,index) in promotions" 
                :key="video.video_id"  
                class="video-box"
              >
                <video
                :src="video.video_clip_link" 
                @play="handlePlay (index)"
                class="song-promotion-video" 
                controls="controls" 
                >
                您的浏览器不支持 video 标签。
                </video>
                <div class="song-promotion-type">{{video.promotion_type}}</div>
              </div>
            </div>
            <div class="song-promotion-title">{{$t('h.song.promoteTitle3')}}</div>
            <p> {{$t('h.song.promotionsText')}}{{promotionsText}}{{$t('h.song.promotionsText1')}}</p>
          </div>
        </div>
        <div class="empty-box" v-else>
            <my-empty :empty-text="ClipEmptyText"></my-empty>
        </div>
      </el-tab-pane>
    </el-tabs>

    <audio-player
      class="audio-player" 
      ref="audioPlayer" 
      :mp3-name="mp3Name" 
      :mp3-url="mp3Url" 
      @updateAudioStatus="updateAudioStatus">
    </audio-player>
  </div>
</template>

<script>
import echarts from "echarts/lib/echarts"
import "echarts/lib/chart/bar"
import "echarts/lib/chart/pie"
import "echarts/lib/chart/radar"
import "echarts/lib/component/tooltip"
import "echarts/lib/component/title"
import "echarts/lib/component/polar"
import FragmentPlayer from '../common/__fragment-player'

export default {
  components: {
    FragmentPlayer
  },

  props: {
    showDialog: {
      type: Boolean,
    },
    songData: {
      default: ""
    }
  },

  data() {
    return {
      dialogTableVisible: false,
      tableData: [],
      chartsData: "",
      ageChart: null,
      cityChart: null,
      genderChart: null,
      // 城市字典表
      cityDict: ["一线","二线", "三线","四线","五线"],
      ageFields: [],
      // 是否已经请求过画像分析数据
      hasChartsInfo: false,
      // 是否已经请求过推荐歌手数据
      hasSingerInfo: false,
      activeTab: "platform_score",
      activeClip: 4,
      loading: false,
      hotSongList: [],
      songPool: '',
      songPoolList: [],
      singerPool: '',
      singerPoolList: [],
      // 预加载音频列表
      preloadAudioList: [],
      clips: '',
      promotion:0,
      promotions:'',
      videos_info:[],
      promotionsTypeList:[] ,
      promotionsText:'',
      videoElement:[],
      ClipEmptyText:'',
      isSpecialPool: false,
      scorllbox:'',
      isAudioPlay:false,
      mp3Name: this.songData.track_name,
      mp3Url: this.songData.mp3Url,
      promotionVersion:'',
    }
    
  },

  computed: {
    token: function () {
      return localStorage.getItem("token");
    },
    tableHeight: function() {
      return(window.innerHeight*0.46);
    }
  },
  watch: {
    showDialog(val) {
      if (val) {
        this.dialogTableVisible = val
      } else {
        this.activeTab = "platform_score"
      }
    },

    activeClip(val){
      this.promotions = []
      if(this.promotionVersion == 'song_promotion_v2'){
        this.promotions = this.clips[val].similar_song
        this.videos_info = this.promotions[0].videos_info
      }else{
        const videoList = this.clips[val].promotions
        this.promotionsTypeList = []
        for(let key in videoList){
          for(let item in videoList[key]){
            console.log(item)
            videoList[key][item]['promotion_type'] = key
            this.promotions.push(videoList[key][item])
          }
          this.promotionsTypeList.push(key)
        }
        this.promotionsText = this.promotionsTypeList.join('、')
        console.log(this.promotions)
      }
      this.videoElement = document.getElementsByTagName('video')
    },
    promotion(val){
      this.$refs.audioPlayer.pauseMusic()
      this.videos_info = this.promotions[val].videos_info
    },
   
  },

  mounted() {
    // 根据屏幕尺寸变化，重新调整图表的宽高
    // 不加setTimeout得不到预期效果
    window.onresize = () => {
      setTimeout(() => {
        if (this.ageChart) {
          this.ageChart.resize();
          this.cityChart.resize();
          this.genderChart.resize();
        }
      });
    };
    
  },
  methods: {
    async clickTab (tab) {
      switch (tab.name) {
        case 'audiences_analyse':
          this.getAnalyseData()
          break
        case 'singer_match':
          this.loading = true
          await this.getSingerPoolList()
          this.getSingerMatch()
          break
        case 'similar_hotsong':
          this.loading = true
          await this.getSongPools()
          this.getSimilarHotSong(1)
          break
        case 'song_promotion':
          this.getSongPromotion()
          break
        default:
          break
      }
    },

    // 获取多平台评分信息
    getPlatformInfo (type, platform) {
      const info = this.songData.multi_layer_result.find(item => item.layer_name === platform)
      if (info) {
        return info[type]
      } else {
        return ''
      }
    },
   
    async getAnalyseData () {
      // 已缓存数据时，不需要重新请求接口
      if (this.hasChartsInfo || this.chartsData.sex) return
      this.loading = true
      const charts = await this.$store.dispatch("getScore", {
        token: this.token,
        song_id: this.songData.id,
        result_type: "audiences_analyse"
      })
      if (charts.success) {
        // 处理年龄分布
        let age = []
        const ageFields = []
        for (let key in charts.result.age) {
          // 保存 key
          ageFields.push(key)
          // 保存 value
          age.push(charts.result.age[key].toFixed(2))
        }
        this.ageFields = this.handleAgeFields(ageFields)
  
        // 处理城市分布
        let city = []
        for (let i in charts.result.city) {
          city.push(charts.result.city[i].toFixed(2))
          
        }
        this.chartsData = charts.result
        this.chartsData.age = age
        this.chartsData.city = city
        this.chartsData.sex = charts.result.sex
        this.hasChartsInfo = true
        if (
          this.chartsData.sex &&
          this.chartsData.city[0] &&
          this.chartsData.age[0]
        ) {
          this.openDialog()
        }
        this.loading = false
      } else {
        this.$message({
          message: charts.msg,
          type: "error"
        })
      }
    },

    copyContactInfo (info) {
      let input = document.createElement('textarea')
      input.value = info
      input.setAttribute('readonly', '')
      document.body.appendChild(input)
      input.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
        this.$message({
          message: '复制成功',
          type: "success",
          offset: 100
        })
      }
      document.body.removeChild(input)
    },

    async getSingerMatch () {
      this.loading = true
      const res = await this.$store.dispatch('getSingerMatch', {
        token: this.token,
        song_id: this.songData.id,
        singer_pool_id: this.singerPool
      })
      if (res.code === 2000) {
        this.tableData = res.result.singer_match
        this.hasSingerInfo = true
        this.preloadAudio(this.tableData)
        this.loading = false
      } else if (res.code === 2001) {
        // 模型推理中，轮询结果
        setTimeout(() => {
          if (this.showDialog) {  
            this.getSingerMatch()
          }
        }, 2000)
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 120
        })
        this.loading = false
      }
    },

    selectSingerPool (val) {
      const el = document.getElementById("mini-player")
      if (el) {
        el.remove()
      }
      const pool = this.singerPoolList.find(item => item.singer_pool_id === val)
      this.singerPool = val
      if (pool && pool.singer_pool_name.includes('K歌')) {
        this.isSpecialPool = true
      } else {
        this.isSpecialPool = false
      }
      this.getSingerMatch()
    },
    
    // 获取宣推建议数据
    async getSongPromotion(){
      this.loading = true
      const res = await this.$store.dispatch("getSongPromotion", {
        token: this.token,
        song_id: this.songData.id
      })
      if(res.code ==2000){
        this.clips = res.promotion_data.clips
        // 触发activeClip的watch        
        this.activeClip = 0
        this.promotionVersion = res.version
        this.loading = false
      }
      else {
        this.ClipEmptyText =res.msg
        this.loading = false
      }
    },

    // 使用新建Audio的方式预加载音频
    // 【歌手推荐】【相似歌曲】内的歌曲需要预加载，原链接仅有30s有效期
    preloadAudio (list) {
      const songUrlArr = []
      list.forEach(item => {
        songUrlArr.push(item.song_link)
      })
      songUrlArr.forEach(item => {
        const audio = new Audio(item)
        this.preloadAudioList.push(audio)
      })
    },

    playMusic(obj) {
      this.handlePlay(999)
      if(obj.row){
        this.mp3Name = obj.row.song_name
        this.mp3Url = obj.row.song_link
      }
      else if (obj.mp3Url) {
        this.mp3Name = obj.name
        this.mp3Url = obj.mp3Url
      }
      else if(obj.song_link){
        this.mp3Name = obj.song_name
        this.mp3Url = obj.song_link
      }
      if (this.isAudioPlay) {
        this.$refs.audioPlayer.pauseMusic()
      } else {
        this.$refs.audioPlayer.playMusic()
      }
    },
    updateAudioStatus (status) {
      this.isAudioPlay = status
    },
    handlePlay (index) {
      if (this.$refs.fragment) {
        this.$refs.fragment[0].pauseMusic()
      }
      this.$refs.audioPlayer.pauseMusic()
      const videoElement = this.videoElement
      if (videoElement && videoElement.length > 0) {
        for (let i = 0; i < videoElement.length; i++) {
          if (i === index) {
            this.videoElement[i].play()
          } else {
            this.videoElement[i].pause()
          }
        }
      }
    },
    handleVideoType(index,url,id,type){
      const videoElement = this.videoElement
      const buttonElement = document.getElementById(id+type)
      if(type =='ori'){
        const element = document.getElementById(id+'syn')
        element.className ='el-button el-button--default video-button-second'
      }else{
        const element = document.getElementById(id+'ori')
        element.className ='el-button el-button--default video-button-first'
      }
      if (videoElement && videoElement.length > 0) {
        for (let i = 0; i < videoElement.length; i++) {
          if (i === index) {
            buttonElement.className +=' video-button-active'
            videoElement[i].src =url
          } 
        }
      }
    },
    async getSingerPoolList () {
      // 已有缓存数据无需往下调用接口
      if (this.singerPoolList && this.singerPoolList.length) return

      const res = await this.$store.dispatch('getSingerPool', {
        token: this.token
      })
      if (res.success) {
        this.singerPoolList = res.singer_pools
        const douyin = this.singerPoolList.find(item => item.singer_pool_name.includes('抖音'))
        this.singerPool = douyin ? douyin.singer_pool_id : this.singerPoolList[0].singer_pool_id
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 120
        })
      }
    },
          
    async getSongPools () {
      // 已有缓存数据无需往下调用接口
      if (this.songPoolList && this.songPoolList.length) return
      const res = await this.$store.dispatch('getSongPoolList', {
        token: this.token
      })
      if (res.success) {
        for (let i in res.result){
          if(res.result[i].id != '2'){
            this.songPoolList.push(res.result[i])
          }
        }
        this.songPool = this.songPoolList[0].id
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 120
        })
      }
    },

    async getSimilarHotSong (refresh) {
      this.loading = true
      const res = await this.$store.dispatch('getSimilarHotSong', {
        token: this.token,
        song_id: this.songData.id,
        song_pool_id: this.songPool,
        refresh: refresh,
      })
      if (res.success) {
        let hotSongList = res.result.simi_songs_result || []
        hotSongList = hotSongList.filter(item => item.score >= 0.8)
        // 歌曲相似度保留两位小数
        hotSongList.forEach(item => {
          item.score = this.$util.round(item.score * 100, 0) + '%'
        })
        this.hotSongList = hotSongList
        this.preloadAudio(hotSongList)
        this.loading = false
      } else if (res.code === 2001) {
        // 2001 表示任务执行中，未获取到数据
        setTimeout(() => {
          if (this.showDialog) {  
            this.getSimilarHotSong()
          }
        }, 2000)
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 120
        })
        this.loading = false
      }
    },

    handleAgeFields(arr) {
      const list = [];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].includes("-")) {
          list.push(arr[i].replace(/-/, "\n" + "/" + "\n"));
        } else {
          list.push(arr[i]);
        }
      }
      return list;
    },

    async choseLike(row, like) {
      if (row.feedback == like) {
        like = "";
      }
      const result = await this.$store.dispatch("putFeedback", {
        token: this.token,
        song_id: this.songData.id,
        rank: row.rank,
        feedback: like,
      });

      if (result.code == 2000) {
        row.feedback = like;
      } else {
        this.$message({
          message: "请求失败，请稍后重试",
          type: "error",
          offset: 120,
        });
      }
    },
    
    closeDialog() {
      this.$emit("closeDialog");
      // 关闭弹框,清空数据
      Object.assign(this.$data, this.$options.data());
    },

    drawAgeChart() {
      let option = {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          offset: 10,
          show: true,
          data: this.ageFields,
          axisLabel: {
            interval: 0,
            overflow: "break",
            margin: 10.5,
            fontSize: 12,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          offset: 8,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
          },
          splitLine: {
            lineStyle: {
              width: 1,
              type: "dashed",
            },
          },
        },
        grid: {
          top: "12%",
          right: "50px",
        },
        series: [
          {
            name: this.$t('h.song.ageDistribution'),
            type: "bar",
            data: this.chartsData.age,
            barWidth: 20,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#ff8c36" }, //柱图渐变色
                  { offset: 1, color: "#ffc599" }, //柱图渐变色
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#ffc599" }, //柱图渐变色
                  { offset: 1, color: "#ff8c36" }, //柱图高亮渐变色
                ]),
              },
            },
          },
        ],
      };
      const ele = this.$refs.age_chart;
      if (ele) {
        let myEchart = echarts.init(ele);
        myEchart.setOption(option);
        this.ageChart = myEchart;
      }
    },

    drawCityChart() {
      const data = this.chartsData.city
      let option = {
        tooltip: {
          trigger: "axis",
        },
          xAxis: {
            offset: 5,
            max: 'dataMax',
            min:70,
            splitLine: {
            lineStyle: {
              width: 1,
              type: "dashed",
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: {
            type: 'category',
            data: this.cityDict,
            inverse: false,
            max: 4 ,
            axisLine: {
            show: false,
          },
            axisTick: {
              show: false,
            },
          },
          grid: {
          top: "5%",
          bottom:"15%",
          left: "50px",
          right: "30px",
        },
          series: [
            {
              realtimeSort: true,
              type: 'bar',
              name: this.$t('h.song.cityDistribution'),
              data: data,
              barWidth: 20,
              itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#ff8c36" }, //柱图渐变色
                  { offset: 1, color: "#ffc599" }, //柱图渐变色
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#ffc599" }, //柱图渐变色
                  { offset: 1, color: "#ff8c36" }, //柱图渐变色
                ]),
              },
            },
          }
              ],
            };
      const ele = this.$refs.city_chart;
      if (ele) {
        let myEchart = echarts.init(ele);
        myEchart.setOption(option);
        this.cityChart = myEchart;
      }
    },

    drawGrenderChart() {
      const male = Math.round(
        (parseFloat(this.chartsData.sex.male.toFixed(2)) /
          (parseFloat(this.chartsData.sex.male.toFixed(2)) +
            parseFloat(this.chartsData.sex.female.toFixed(2)))) *
          100
      );
      const female = 100 - male;
      let option = {
        title:[ 
          {
            text: male + "%",
            x: "40%",
            y: "38%",
            textAlign: "center",
            textStyle: {
              fontSize: "150%",
              fontWeight: "400",
              color: "#A2A3B3",
              textAlign: "center",
            },
          },
          {
            text: female + "%",
            left: "58%",
            top: "56%",
            textAlign: "center",
            textStyle: {
              fontSize: "150%",
              fontWeight: "400",
              color: "#A2A3B3",
              textAlign: "center",
            },
          },
        ],
        series: [{
          type: 'pie',
          radius: ['70%', '90%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#f5f6fa",
            borderWidth: 2
          },
          hoverAnimation: false,
          label: {
            show: false,
            position: 'center'
          },
          data: [
            { value: female, 
              name: 'female' ,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#fdf6b4" }, //柱图渐变色
                  { offset: 1, color: "#fae62b" }, //柱图渐变色
                ])
              }
            },
            { value: male, 
              name: 'male' ,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#ff8c36" }, //柱图渐变色
                  { offset: 1, color: "#ffc599" }, //柱图渐变色
                ])
              }
            },
          ]
        }]
      };
      const ele = this.$refs.gender_chart;
      if (ele) {
        let myEchart = echarts.init(ele);
        myEchart.setOption(option);
        this.genderChart = myEchart;
      }
    },

    openDialog() {
      setTimeout(() => {
        //  执行echarts画图方法
        this.drawAgeChart();
        this.drawCityChart();
        this.drawGrenderChart();
      }, 0);
    },
  },
};
</script>


<style lang="stylus" scoped>
.more-analyse {
  height: 100%;
  display: flex;
  flex-direction: column;


  .main-top-text {
    font-size: 18px;
    font-weight: 600;
    color: #1a1a1a;

    span {
      color:#ff8c36
    }

    .play-btn {
      margin-left: 15px;
      font-size: 20px;
      color: #777;
      position: relative;
      top: 2px;
    }
  }
  .level-tips{
    color: #B7B7B8;
    font-size: 13px;
    line-height:24px;
    margin: 15px 10px;
  }
  .card-box {
    display: flex;
    color: #848585;
    text-align: center;

    .result-card {
      background: #f5f6fa;
      padding: 0 20px 20px 20px;
      border-radius: 6px;
    }

    .flex-box {
      display: flex;
      justify-content: space-between;
    }

    .platform-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .platform-level {
      position: relative;
      font-family: Gilroy-Bold;
      top: 25px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 50px;
      background: linear-gradient(to bottom right, #ffd5b3 0%, #ff8c36 100%);
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      color: #fff;
    }

    .white-card {
      background: #fff;
      width: 150px;
      border-radius: 6px;
    }

    .platform-score {
      width: 100%;
      height: 50px;
      margin: 35px auto 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      padding-right: 4px;
    }

    .score {
      color: #000;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: 600;
    }

    .platform-name {
      border-top: 2px solid #f7f8f8;
      padding: 10px;
      font-weight: 600;
    }
  }

  .chart-box {
    display: flex;
    width:100%;
  }

  .dialog_gender, .dialog_city, .dialog_age {
    background-color: #f5f6fa;
    width: 30%;
    height: 380px
    font-weight: 600;
    border-radius: 5px;
    overflow: visible;
    padding: 15px;
    margin-right: 10px;
  }

  .dialog_city {
  }

  .dialog_age {
    font-weight: 600;
    border-radius: 5px;
    padding: 15px;
  }

  .dialog_gender {
    position: relative;
  }


  .pagination {
    float: right;
    margin-top: 15px;
  }

  .levelText {
    margin-left: 35%;
    font-size: 70px;
    color: #635cfe;
  }

  .levelTextN {
    font-size: 20px;
  }

  .slash {
    position: absolute;
    z-index: 10000;
    width: 180px;
    height: 1px;
    background: #ffe8d7;
    top: 190px;
    left: 105px;
    transform: rotate(-45deg);
  }

  .gender_female, .gender_male {
    font-size: 10px;
    font-weight: 200;
    position: absolute;
    bottom: 20px;
    color: #A2A3B3;
  }

  .like {
    color: #ff8c36;
  }

  .dislike {
    color: #FF3B3B;
  }

  .gender_male {
    left: 50px;
  }

  .gender_female {
    right: 40px;
  }

  .dialog_flex {
    display: flex;
    flex-direction: inherit;
    align-items: center;
    justify-content: center;
  }

  .pbox, .sbox {
    position: relative;
    height: 30px;
    line-height: 30px;
  }

  .player {
    font-size: 16px;
    color: #777777;
    cursor: pointer;
  }

  .tips {
    font-weight: 300;
    color: #B7B7B8;
    font-size: 12px;
    margin: 15px 10px 0 0;
  }

  .point {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 5px;
  }

  .male {
    background: linear-gradient(270deg, #ffc599 0%, #ff8c36 100%);
  }

  .female {
    background: linear-gradient(270deg, #ffff00 0%, #ffffb3 100%);
  }

  #age {
    width: 100%;
    height: 360px;
    margin: auto;
  }

  #city {
    width: 100;
    height: 380px;
    margin: auto;
  }

  #gender {
    width: 80%;
    height: 320px;
    margin: auto;
    overflow: visible;
  }

  .song-pool {
    display: flex;
    margin: 0 0 20px 0;
    line-height: 40px;
  }

  .singer-pool {
    display: flex;
    align-items: flex-start;
    margin: 0 0 15px 0;
  }

  .singer-pool-group {
    flex: 1;
    line-height: 24px;
  }

  .pool-tips {
    font-size: 10px; 
    color: #b7b7b8;
    margin-top: 14px;
    line-height: 0px;
  }
  .song-promotion {
    flex: 1;
    overflow: hidden;
  }
  .song-promotion-title {
    font-size: 14px;
    color: black;
    font-weight: 600;
    margin: 20px 0;
  }
  .song-promotion-songclips{
    display:flex
    justify-content: center
  }
  .song-promotion-songplayer{
    display:flex
  }
  .song-promotion-videos{
    display: flex;
    flex-wrap: wrap;
  }
  .song-promotion-videos-v2{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: -20px 0 0 -50px;
  }
  .song-promotion-video-v2{
    width: 15vw;
    height: 26vw;
    background-color: #000;
    position:relative;
  }
  .video-info {
    display: flex;
    align-items: center;
    margin-top: 10px; 
  }
  .video-title{
    text-align: left;
    width: 12vw;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .video-box-v2{
    display:flex;
    flex-flow: column;
    text-align: center;
    margin: 15px 50px ;
    position:relative;
  }
  .video-button-first{
    border-radius:5px 0 0 5px 
  }
  .video-button-second{
    border-radius: 0 5px 5px 0
  }
  .video-button-active{
    color: #ff8c36;
    border-color: #ffe8d7;
    background-color: #fff4eb;
  }
  .song-promotion-video{
    width: 15vw;
    height: 26vw;
    background-color: #000;
    position:relative;
  }
  .video-box{
    margin: 15px 25px;
    position:relative;
  }
  .song-promotion-type{
    position:absolute;
    top:0px;
    left:0px;
    padding:10px 15px;
    border-radius:0 0 10px 0;
    background:#777777;
    color:#fff;
    font-weight:600;
    z-index: 2;
  }
  .search-level ::v-deep .el-input__inner {
    width: 300px;
  }
  .audio-player {
    display: fixed;
    margin: 0 auto;
    width: 700px;
  }
  .empty-div{
    .my-empty{
      margin: 50px auto;
    }
  }
  .empty-box{
    .my-empty{
      margin:150px auto;
    }
  }
}
::v-deep .el-dialog {
  border-radius: 8px;
  width: 850px;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__header {
  font-weight: 600;
  background: #f5f6fa;
  border-radius: 8px 8px 0 0;
  padding: 10px 0;
}

::v-deep .el-dialog__headerbtn {
  top: 14px;
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: rgb(250, 250, 252);
}
::v-deep .el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
::v-deep .el-tabs__content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
::v-deep .el-tab-pane {
  flex: 1;
  overflow: scroll;
}
</style>