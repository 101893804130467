import Vue from 'vue'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
import PdfTemplate from './pdf-template.vue'
import util from '@/lib/util'

/* 前端导出pdf思路：
   1、绘制出一首歌曲的html页面
   2、html转为canvas
   3、canvas生成图片
   4、多张图片依次加入到pdf文件中
   5、保存pdf
*/

let pdfhtml = Vue.extend(PdfTemplate)
let canvasList = []

async function createPdf (options) {
    const clientWidth = document.body.clientWidth
    // 根据屏幕大小确定导出pdf的缩放程度（测试结果显示屏幕越小的时候导出内容越模糊）
    let scale = 1.5
    if (clientWidth < 1441) {
        scale = 2
    } else if (clientWidth < 1921) {
        scale = 1.7
    }
    
    for (let i = 0; i < options.length; i++) {
        // 新增一个html节点，即歌曲分析内容
        let printerDom = new pdfhtml({data: options[i]}).$mount()
        document.body.appendChild(printerDom.$el)
        // 等待渲染的时间
        await util.sleep(2500)
        await getCanvasData(document.querySelector(`#song_id${options[i].songData.song_id}`), scale)
        document.body.removeChild(printerDom.$el)
    }

    let pdf = ''
    for (let i = 0; i < canvasList.length; i++) {
        let canvas = canvasList[i]
        let contentWidth = canvas.width
        let contentHeight = canvas.height
        //a4纸的宽高尺寸[595.28,841.89]，按比例换算html页面生成的canvas在pdf中图片的宽高
        const imgWidth = 595.28
        let imgHeight = 595.28/contentWidth * contentHeight
        let pageData = canvas.toDataURL('image/jpeg', 1.0)
        if (!pdf) {
            // 首页，新增一个pdf
            pdf = new JsPDF('', 'pt', [595.28, imgHeight])
        } else {
            // 非首页，新开一页
            pdf.addPage([imgWidth, imgHeight])
        }
        pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight, `alias${i+1}`, 'SLOW')
    }

    pdf.save(`歌曲分析【${util.formatDate(null, 'datetime')}】`)
    canvasList = []
}

async function getCanvasData (ele, scale) {
    await html2canvas(ele, {
        scale: scale
    }).then( (canvas)=>{
        canvasList.push(canvas)
    })
}

export default createPdf