<template>
  <div :id="'song_id' + songData.song_id" class="pdf-template">
    <img class="pdm-logo" src="~assets/home/logo-black.png" alt="" />
    <div class="song-name">《{{ songData.track_name }}》</div>

    <div class="template-box">
      <p class="title">多平台评分</p>

      <div class="card-box">
        <div class="result-card">
          <div class="flex-box">
            <div class="platform-card">
              <div class="platform-level">{{ getPlatformInfo('level', 'qq') }}</div>
              <div class="white-card">
                <div class="platform-score">
                  <p class="pingfen">评分</p>
                  <p class="score">{{ getPlatformInfo('score', 'qq') }}</p>
                </div>
                <div class="platform-name">QQ音乐</div>
              </div>
            </div>

            <div v-if="songData.multi_layer_result && songData.multi_layer_result.length > 1" class="platform-card ml20">
              <div class="platform-level">{{ getPlatformInfo('level', 'kg') }}</div>
              <div class="white-card">
                <div class="platform-score">
                  <p class="pingfen">评分</p>
                  <p class="score">{{ getPlatformInfo('score', 'kg') }}</p>
                </div>
                <div class="platform-name">酷狗音乐</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="result-desc">
        <p>等级说明：（PDM打分由TME曲库海量上亿数据分析得出，评级代表预测歌曲在市场上对应的播放量表现，与音乐性的好坏无关。）</p>
        <p>1、S级表示高潜作品，一定量的推广前提下，13%概率达到日播100w。市场一般水平歌曲相应概率为3%。</p>
        <p>2、A级表示优质作品，一定量的推广前提下，6%概率达到日播100w。市场一般水平歌曲相应概率为3%。</p>
        <p>3、B级歌曲为较优质歌曲，可覆盖80%的市面爆款歌曲。</p>
        <p>4、C级歌曲市场潜力相对较低。</p>
      </div>
    </div>

    <div v-if="type === 'assess'" class="template-box">
      <p class="title">画像分析</p>
      <div class="chart-box">
        <div>
          <div class="dialog_gender">
            <div style="padding: 15px">
              <p>性别占比</p>
              <div ref="gender_chart" id="gender"></div>
              <div class="slash"></div>
              <div class="gender_male">
                <i class="point male"></i>
                男性
              </div>
              <div class="gender_female"><i class="point female"></i> 女性</div>
            </div>
          </div>
          <div class="dialog_city">
            <div style="padding: 15px">
              <p>城市群分布</p>
              <div ref="city_chart" id="city"></div>
            </div>
          </div>
        </div>
        <div class="dialog_age">
          <div style="padding: 15px">
            <div>年龄群分布</div>
            <div ref="age_chart" id="age"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="template-box">
      <p class="title">{{type === 'assess' ? '翻唱达人推荐' : '歌手推荐'}}</p>
      <el-table
        :data="tableData"
        stripe
        :header-cell-style="{
          color: '#aaa',
          background: '#FAFAFC',
        }"
        style="width: 100%"
      >
        <my-empty
          slot="empty"
          empty-text="在歌手池中未匹配到合适歌手"
        ></my-empty>
        <el-table-column
          show-overflow-tooltip
          prop="singer_name"
          label="歌手"
          align="center"
        >
        </el-table-column>

        <el-table-column label="参考歌曲" prop="song_name" align="center">
        </el-table-column>
      </el-table>
      <div class="tips">* 以上歌曲参考歌手声线，排名不分先后</div>
    </div>

    <div v-if="type === 'demo'" class="template-box">
      <p class="title">相似歌曲</p>
      <el-table
        :data="similarSongs"
        stripe
        :header-cell-style="{
          color: '#aaa',
          background: '#FAFAFC',
        }"
        style="width: 100%"
      >
        <my-empty
          slot="empty"
          empty-text="暂无相似爆款数据"
        ></my-empty>

        <el-table-column prop="song_name" label="歌曲" align="center">
          <template slot-scope="scope">
            <p class="can-select">{{ scope.row.song_name }}</p>
          </template>
        </el-table-column>

        <el-table-column prop="singer_name" label="歌手" align="center">
          <template slot-scope="scope">
            <p class="can-select">{{ scope.row.singer_name }}</p>
          </template>
        </el-table-column>

        <el-table-column prop="score" label="相似度" align="center">
          <template slot-scope="scope">
            <p class="can-select">{{ Math.round(scope.row.score*100) }}%</p>
          </template>
        </el-table-column>
      </el-table>
      <div class="tips">* 歌曲来自爆款歌曲池，相似度>80%更具参考性</div>
    </div>
    <div v-if="type === 'assess' && songPromotion" class="template-box">
       <p class="title">宣推建议</p>
      <div class="song-promotion-title">精彩片段</div>
      <div class= "fragment-player">
        <p class="time">00:00</p>
        <div class="progress-box">
          <div id="fragmentTrack" style="width:850px" ref="fragmentTrack" class="progress-track">
              <!-- 总进度 -->
              <div class="play-bar-wrap">
                  <!-- 片段 -->
                  <div class="fragment-bar" 
                    :style="{ 
                    left: fragmentLeft + 'px', 
                    width: (endTime-starting_timestamp) / totalTime * 850+ 'px' }">
                  </div>
              </div>
              <!-- 进度按钮 -->
              <div>
                <div 
                :style="{
                left: fragmentLeft - 8 + 'px', }"
                class="progress-thumb" >
                </div>
                <div class="progress-time"
                :style="{
                left: fragmentLeft - 20 + 'px', }"
                >{{$util.timeToString(starting_timestamp)}}</div>
              </div>
              <div>
                <div 
                :style="{
                left: fragmentRight - 8 + 'px', }"
                class="progress-thumb" >
                </div>
                <div class="progress-time"
                :style="{
                left: fragmentRight - 20 + 'px', }"
                >{{$util.timeToString(endTime)}}</div>
              </div>
          </div>
        </div>
        <p class="time">{{$util.timeToString(totalTime)}}</p>
      </div>
      <div class="song-promotion-title">短视频推荐</div>
      <div v-if="version === 'song_promotion_v2'">
        <el-table
          :data="songPromotion.clips[0].similar_song"
          stripe
          :header-cell-style="{
            color: '#aaa',
            background: '#FAFAFC',
          }"
          style="width: 100%"
        >
          <my-empty
            slot="empty"
            empty-text="暂无短视频推荐数据"
          ></my-empty>
          <el-table-column prop="song_name" label="相似歌曲名称" align="center">
          </el-table-column>
          <el-table-column prop="singer_name" label="Top3高赞视频" align="center">
            <template slot-scope="scope">
              <div class="flexbox">
                <div
                  class="qrcode-box"
                  v-for="video in scope.row.videos_info"
                  :key = video.video_id 
                  :id = video.video_id>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <div class="song-promotion-bar song-promotion-bar-dark">
          <div class="song-promotion-bar-title1">短视频类型</div>
          <div class="song-promotion-type"  v-for="item in songPromotion.promotions" :key = item.promotion_type>
            {{item.promotion_type}}
          </div>
        </div>
        <div class="song-promotion-bar">
          <div class="song-promotion-bar-title2">扫码查看视频效果</div>
          <div class="song-promotion-qrcode" 
            v-for ="item in songPromotion.promotions" 
            :key = item.promotion_type 
            :id = item.promotion_type>
          </div>
        <!-- <div class="song-promotion-title">推广建议</div>
        <p> 适合搭配{{promotionsText}}等类型的视频</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/radar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/title";
import "echarts/lib/component/polar";

export default {

  props: {
    options: {
      type: Object,
    },
  },

  data() {
    return {
      type: "assess",
      songData: {},
      chartsData: [],
      tableData: [],
      similarSongs: [],
      cityDict: ["一线","二线", "三线","四线","五线"],
      ageFields: [],
      songPromotion:[],
      totalTime:'00:00',
      endTime:'',
      fragmentLeft:'',
      fragmentRight:'',
      starting_timestamp:'',
      version:'',
      // promotionsText:''
    }
  },

  mounted() {
    if (this.type === "assess") {
      setTimeout(() => {
        this.drawAgeChart();
        this.drawCityChart();
        this.drawGrenderChart();
      }, 0);
    }
    if(this.songPromotion){
        setTimeout(() => {
        this.drawQrcode();
        this.handleAudio()
      }, 0);
    }
  },

  methods: {
    drawAgeChart() {
      let option = {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          offset: 10,
          show: true,
          data: this.ageFields,
          axisLabel: {
            interval: 0,
            overflow: "break",
            margin: 10.5,
            fontSize: 13,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          offset: 10,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
          },
          splitLine: {
            lineStyle: {
              width: 1,
              type: "dashed",
            },
          },
        },
        grid: {
          top: "20%",
          left: "50px",
          right: "50px",
        },
        series: [
          {
            name: "年龄群分布",
            type: "bar",
            data: this.chartsData.age,
            barWidth: 40,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#ff8c36" }, //柱图渐变色
                  { offset: 1, color: "#ffc599" }, //柱图渐变色
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#ffc599" }, //柱图渐变色
                  { offset: 1, color: "#ff8c36" }, //柱图高亮渐变色
                ]),
              },
            },
          },
        ],
      };
      const ele = this.$refs.age_chart;
      if (ele) {
        let myEchart = echarts.init(ele);

        myEchart.setOption(option);
      }
    },

    drawCityChart() {
       const data = this.chartsData.city
      let option = {
        tooltip: {
          trigger: "axis",
        },
          xAxis: {
            offset: 5,
            max: 'dataMax',
            min:70,
            splitLine: {
            lineStyle: {
              width: 1,
              type: "dashed",
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: {
            type: 'category',
            data: this.cityDict,
            inverse: false,
            max: 4 ,
            axisLine: {
            show: false,
          },
            axisTick: {
              show: false,
            },
          },
          grid: {
          top: "5%",
          bottom:"15%",
          left: "50px",
          right: "30px",
        },
          series: [
            {
              realtimeSort: true,
              type: 'bar',
              name:'城市群分布',
              data: data,
              barWidth: 20,
              itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#ff8c36" }, //柱图渐变色
                  { offset: 1, color: "#ffc599" }, //柱图渐变色
                ]),
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#ffc599" }, //柱图渐变色
                  { offset: 1, color: "#ff8c36" }, //柱图渐变色
                ]),
              },
            },
          }
              ]
            };
      const ele = this.$refs.city_chart;
      if (ele) {
        let myEchart = echarts.init(ele);

        myEchart.setOption(option);
      }
    },

    drawGrenderChart() {
     const male = Math.round(
        (parseFloat(this.chartsData.sex.male.toFixed(2)) /
          (parseFloat(this.chartsData.sex.male.toFixed(2)) +
            parseFloat(this.chartsData.sex.female.toFixed(2)))) *
          100
      );
      const female = 100 - male;
      let option = {
      title:[ {
            text: male + "%",
            x: "40%",
            y: "35%",
            textAlign: "center",
            textStyle: {
              fontSize: "100%",
              fontWeight: "400",
              color: "#A2A3B3",
              textAlign: "center",
            },
          },
          {
            text: female + "%",
            left: "58%",
            top: "53%",
            textAlign: "center",
            textStyle: {
              fontSize: "100%",
              fontWeight: "400",
              color: "#A2A3B3",
              textAlign: "center",
            },
          },
        ],
      series: [{
        type: 'pie',
        radius: ['75%', '95%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#f5f6fa',
          borderWidth: 3
        },
        hoverAnimation: false,
        label: {
        show: false,
        position: 'center'
      },
      
      data: [
        { value: female, 
          name: 'female' ,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#fdf6b4" }, //柱图渐变色
              { offset: 1, color: "#fae62b" }, //柱图渐变色
            ])
          }
        },
        { value: male, 
          name: 'male' ,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#ff8c36" }, //柱图渐变色
              { offset: 1, color: "#ffc599" }, //柱图渐变色
            ])
          }
        },
      ]
    }
  ]
};
      const ele = this.$refs.gender_chart;
      if (ele) {
        let myEchart = echarts.init(ele);

        myEchart.setOption(option);
      }
    },
    drawQrcode(){
      if(this.version === 'song_promotion_v2'){
        if (!this.songPromotion.clips) return
        const promotions = this.songPromotion.clips[0].similar_song
        for(let i in promotions){
          for(let a in promotions[i].videos_info){
            let qrcode = new QRCode(promotions[i].videos_info[a].video_id,{
            width:200,
            height:200,
            correctLevel : QRCode.CorrectLevel.L
          });
          qrcode.makeCode(promotions[i].videos_info[a].video_clip_link)
          }
        }
      }
      else{
        if (!this.songPromotion.promotions) return
        const promotions = this.songPromotion.promotions
        for(let i in promotions){
          let qrcode = new QRCode(promotions[i].promotion_type,{
            width:200,
            height:200,
            correctLevel : QRCode.CorrectLevel.L
          });
          qrcode.makeCode(promotions[i].video_clip_link)
        }
      }
      
    },
    handleAudio(){
      if (!this.songPromotion.ori_song_link) return
      const url = this.songPromotion.ori_song_link
      const audioElement = new Audio(url);
      audioElement.addEventListener('loadeddata', () => {
        this.totalTime = audioElement.duration;
        if(this.version === 'song_promotion_v2'){
          this.endTime = this.songPromotion.clips[0].point[1]
          this.starting_timestamp = this.songPromotion.clips[0].point[0]
        }else{
          this.endTime = this.songPromotion.starting_timestamp + 30
          this.starting_timestamp = this.songPromotion.starting_timestamp
        }
        this.fragmentLeft = this.starting_timestamp / this.totalTime * 850
        this.fragmentRight = this.endTime / this.totalTime * 850 
        console.log( this.fragmentRight)
        if (this.endTime > this.totalTime){
          this.fragmentRight = 850
          this.endTime = ''
        }
      });
      
    },
    // 获取多平台评分信息
    getPlatformInfo (type, platform) {
      const info = this.songData.multi_layer_result.find(item => item.layer_name === platform)
      if (info) {
        return info[type]
      } else {
        return ''
      }
    }
  },
};
</script>

<style lang="stylus">
.pdf-template {
  position: absolute;
  padding-top: 50px;
  width: 1200px;
  min-height: 1700px;
  left: -10000px;
  top: -10000px;
  z-index: -1;

  .pdm-logo {
    width: 200px;
    padding: 0 20px 0 50px;
  }

  .song-name {
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    margin: 20px 0 10px 0;
  }

  .template-box {
    margin: 20px auto 60px;
    width: 1100px;

    .title {
      color: #ff8c36;
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 20px;
      padding-left: 5px;
      border-left: 3px solid #ff8c36;
    }
  }

  .card-box {
    display: flex;
    color: #848585;
    text-align: center;

    .result-card {
      background: #f5f6fa;
      padding: 0 20px 20px 20px;
      border-radius: 6px;
    }

    .flex-box {
      display: flex;
      justify-content: space-between;
    }

    .platform-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .platform-level {
      position: relative;
      font-family: Gilroy-Bold;
      top: 25px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 50px;
      background: linear-gradient(to bottom right, #ffd5b3 0%, #ff8c36 100%);
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      color: #fff;
    }

    .white-card {
      background: #fff;
      width: 150px;
      border-radius: 6px;
    }

    .platform-score {
      width: 100%;
      height: 50px;
      margin: 35px auto 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .pingfen {
      padding: 4px 4px 0 0;
    }

    .score {
      color: #000;
      font-family: Gilroy-Bold;
      font-size: 24px;
      font-weight: 600;
    }

    .platform-name {
      border-top: 2px solid #f7f8f8;
      padding: 10px;
      font-weight: 600;
    }
  }

  .result-desc {
    margin-top: 30px;
    font-size: 14px;
    font-family: "PingFang SC", "Microsoft YaHei";
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 26px;
  }

  .chart-box {
    display: flex;
    flex-wrap: wrap;
  }

  .dialog_gender, .dialog_city {
    background-color: #f5f6fa;
    width: 400px;
    font-weight: 600;
    border-radius: 5px;
    overflow: visible;
    margin-right: 30px;
  }

  .dialog_age {
    background-color: #f5f6fa;
    width: 660px;
    font-weight: 600;
    border-radius: 5px;
    overflow: visible;
    height: 560px;
  }

  .dialog_city {
    margin-top: 30px;
    height: 270px;
  }

  .dialog_gender {
    height: 260px;
    position: relative;
  }

  .pagination {
    float: right;
    margin-top: 15px;
  }

  .levelText {
    margin-left: 35%;
    font-size: 70px;
    color: #635cfe;
  }

  .levelTextN {
    font-size: 20px;
  }

  .slash {
    position: absolute;
    z-index: 10000;
    width: 80px;
    height: 2px;
    background: #ffe8d7;
    top: 125px;
    left: 160px;
    transform: rotate(-45deg);
  }

  .gender_female, .gender_male {
    font-size: 14px;
    font-weight: 200;
    position: absolute;
    bottom: 20px;
    color: #A2A3B3;
  }

  .like {
    color: #ff8c36;
  }

  .dislike {
    color: #FF3B3B;
  }

  .gender_male {
    left: 40px;
  }

  .gender_female {
    right: 40px;
  }

  .dialog_flex {
    display: flex;
    flex-direction: inherit;
    align-items: center;
    justify-content: center;
  }

  .player {
    font-size: 16px;
    color: #777777;
  }

  .tips {
    font-weight: 300;
    color: #B7B7B8;
    font-size: 12px;
    margin: 15px 10px;
  }

  .point {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 5px;
  }

  .male {
    background: linear-gradient(270deg, #ffc599 0%, #ff8c36 100%);
  }

  .female {
    background: linear-gradient(270deg, #ffff00 0%, #ffffb3 100%);
  }

  #age {
    width: 600px;
    height: 480px;
    margin: auto;
  }

  #city {
    width: 320px;
    height: 230px;
    margin: auto;
  }

  #gender {
    width: 240px;
    height: 200px;
    margin: auto;
    overflow: visible;
  }
  .fragment-player {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
      .time {
          font-size: 16px;
          padding: 0 20px;
      }
      .progress-track {
            // width: 600px;
            height: 14px;
            position: relative;
      }
      .play-bar-wrap {
            border-radius: 6px;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            overflow: hidden;
            background: rgba(0, 0, 0, 0.04);
      }

      .fragment-bar {
            position: absolute;
            top: 0;
            bottom: 0;
            background: #ffd3b3;
      }

      .progress-thumb {
            position: absolute;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #fff;
            border: 2px solid #ff8c36;
            top: -3px;
            left: -8px;
      }
      .progress-time {
            position: absolute;
            top: 20px;
            font-size:16px;
        }
    }
  .qrcode-table{
    width: 100%;
    border-collapse:collapse;
    text-align: center;
    th{
      height: 40px
      font-weight: 600;
    }
  }
  .qrcode-box{
    margin:20px
  }
  .song-promotion-title {
    font-size:22px;
    color:  black;
    font-weight: 600;
    height: 50px;
    margin-top: 30px;
  }
  .song-promotion-bar{
    display:flex;
    justify-content: space-around;
    font-size: 18px;
    line-height: 66px;
    color: #727274;
    text-align: center;
    padding: 0 20px;
  }
  .song-promotion-bar-dark{
    background-color: #F5F7FA;
    border-radius: 10px ;
    }
  .song-promotion-bar-title1 ,.song-promotion-bar-title2{
    font-size: 18px;
    font-weight:600;
    line-height: center;
    width: 240px;
  }
  .song-promotion-bar-title2{
    line-height: 200px;
  }
  .song-promotion-qrcode {
    margin: 50px auto 0;
  }
  .song-promotion-type{
    flex: 1;
    text-align: center;
  }
}
</style>