<template>
<div class="page-songlist">
  <!-- 更多分析 -->
  <track-analyse
    v-if="dialogTableVisible && $store.state.user.country == 'China' && $store.state.user.usertype != 'afrotunes'"
    :show-dialog="dialogTableVisible"
    :song-data="dialogdata"
    @closeDialog="dialogTableVisible = false"
    @stopAudio="stopAudio"
  ></track-analyse>
  <overseas-analyse
    v-else-if="dialogTableVisible && ($store.state.user.country != 'China'|| $store.state.user.usertype == 'afrotunes')"
    :show-dialog="dialogTableVisible"
    :song-data="dialogdata"
    :modelList= currentModelList
    @closeDialog="dialogTableVisible = false"
    @stopAudio="stopAudio"
  ></overseas-analyse>
  <div class="main-songlist" v-else v-loading="loading" >
    <div class="main-top" v-show="!downloadshow">
      <el-row class="mb20" type="flex" justify="space-between" align="middle">
        <el-col :span="6">
          <div class="main-top-text" v-if="$route.path.includes('demo')">
            {{$t('h.song.demo')}}
          </div>
          <div class="main-top-text" v-if="$route.path.includes('assess')">
            {{$t('h.song.assess')}}
          </div>
        </el-col>
        <el-col :span="2.5" v-if="$store.state.user.country == 'China'">
          <el-button round class="el-icon-s-operation" @click="downloadShow">
            {{$t('h.song.batchDownload')}}
          </el-button>
        </el-col>
      </el-row>
      <el-form :inline="true">
        <el-form-item :label=" $t('h.task.uploadTaskName') + ':'">
          <el-input
            @blur="searchList(),setSearchData()"
            @clear="searchList(),setSearchData()"
            clearable
            v-model="search_task_name"
            :placeholder="$store.state.user.usertype  === 'afrotunes' ? $t('h.song.taskNameMsg2') : $t('h.song.taskNameMsg')"
          ></el-input>
        </el-form-item>
        <el-form-item  v-if="$store.state.user.country == 'China'" :label="$t('h.song.scoreResult')+':'" class="search-level">
          <el-select
            v-model="level"
            clearable
            :placeholder="$t('h.song.all')"
            @change="searchList(),setSearchData()"
          >
            <el-option key="S" label="S" value="S"></el-option>
            <el-option key="A" label="A" value="A"></el-option>
            <el-option key="B" label="B" value="B"></el-option>
            <!-- <el-option
              key="XX"
              label="评级不通过"
              value="评级不通过"
            ></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('h.song.releaseTime') + ':'">
          <el-date-picker
            v-model="date"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            :start-placeholder="$t('h.song.startTime')"
            :end-placeholder="$t('h.song.endTime')"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            @change="searchList(),setSearchData()"
          >
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item>
          <span class="only-mark">{{$t('h.song.onlyMarked')}}</span>
          <el-checkbox
            v-model="is_mark"
            label=""
            @change="searchList(),setSearchData()"
          ></el-checkbox>
        </el-form-item> -->
      </el-form>
    </div>

    <div class="main-top" v-if="downloadshow">
      <el-row type="flex" justify="end">
        <el-button class="cancelBtn" @click="downloadShow(), toggleSelection()">
          {{$t('h.song.cancel')}}
        </el-button>
        <el-button class="downloadBtn" v-if="$store.state.user.country == 'China'" type="primary" @click="exportFile('excel', true)">
          {{$t('h.song.exportExcel')}}
        </el-button>
        <el-button class="downloadBtn" v-if="$store.state.user.country == 'China'" type="primary" @click="exportFile('pdf', true)">
          {{$t('h.song.exportPDF')}}
        </el-button>
      </el-row>
      <el-row type="flex" justify="space-between" class="main-download">
        <el-col :span="6">
          <div class="choseText">
            {{$t('h.song.selectItems')}}
            <span class="primary fw600">{{ downloadIDList.length }}</span>
            {{$t('h.song.selectItems2')}}
          </div>
        </el-col>
        <el-col :span="4">
          <div class="tar">
            <span @click="toggleALLSelection" class="main-wipe-buttom">
              {{$t('h.song.selectAll')}}
            </span>
            <span @click="toggleSelection" class="main-wipe-buttom">
              {{$t('h.song.empty')}}
            </span>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-table
      ref="multipleTable"
      class="main-table"
      :data="tempList"
      :resizable="false"
      :header-cell-style="{
        background: '#F5F7FA',
      }"
      :row-key="getRowKeys"
      :row-class-name="tableRowClassName"
      @selection-change="handleSelectionChange"
    >
      <my-empty slot="empty" :empty-text="$t('h.song.emptyText')"></my-empty>
      <el-table-column
        v-if="downloadshow"
        :reserve-selection="true"
        type="selection"
        show-overflow-tooltip
        width="80px"
      >
      </el-table-column>
      
      <el-table-column v-if="$store.state.user.country === 'China'" min-width="10%" align="center">
        <template slot-scope="scope">
          <div v-if="!scope.row.note">
            <!-- <i
              class="mark-icon"
              :class="
                scope.row.is_tab
                  ? 'icon-marked primary'
                  : 'icon-unmark light-gray'
              "
              @click="changeMark(scope.row)"
            ></i> -->

            <i class="icon-lyric lyric" @click="showSongLyric(scope.row.song_id)"></i>
          </div>
          <el-tooltip v-else  effect="dark" placement="bottom">
            <div slot="content">{{ scope.row.note }}</div>
            <i class="el-icon-warning-outline red fs16 ml15"></i>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column
        property="song_name"
        :label="$t('h.song.songName')"
        min-width="26%"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        property="task_name"
        :label=" $t('h.task.uploadTaskName')"
        min-width="20%"
        show-overflow-tooltip
      ></el-table-column>
      
      <el-table-column
        show-overflow-tooltip
        property="create_time"
        min-width="20%"
        :label="$t('h.song.releaseTime')"
      ></el-table-column>

      <el-table-column
        v-if="$store.state.user.country == 'China'"
        show-overflow-tooltip
        :label="$t('h.song.qqmusic_rank')"
        min-width="12%"
        align="center"
      >
        <template slot-scope="scope">
          {{ getPlatformInfo(scope.row.multi_layer_result, 'level', 'qq') }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="$store.state.user.country == 'China'"
        show-overflow-tooltip
        :label="$t('h.song.qqmusic_score')"
        min-width="12%"
      >
        <template slot-scope="scope">
          {{ getPlatformInfo(scope.row.multi_layer_result, 'score', 'qq') }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="$store.state.user.country == 'China'"
        show-overflow-tooltip
        :label="$t('h.song.kugoumusic_rank')"
        min-width="12%"
        align="center"
      >
        <template slot-scope="scope">
          {{ getPlatformInfo(scope.row.multi_layer_result, 'level', 'kg') }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="$store.state.user.country == 'China'"
        show-overflow-tooltip
        :label="$t('h.song.kugoumusic_score')"
        min-width="12%"
      >
        <template slot-scope="scope">
          {{ getPlatformInfo(scope.row.multi_layer_result, 'score', 'kg') }}
        </template>
      </el-table-column>

      <!-- 海外模型打分 -->
      <el-table-column
        v-for="model in currentModelList"
        :key="model"
        :label="modelList[model] + ' ' + $t('h.song.score')"
        min-width="20%"
        align="center"
      >
        <template slot-scope="scope">
          {{getRegionPlatformInfo(scope.row.multi_layer_result, 'score', model)}}
        </template>
      </el-table-column>

      <el-table-column min-width="15%" :label="$t('h.song.play')" align="center">
        <div slot-scope="scope">
          <i
            class="player"
            :class="isAudioPlay && scope.row.file_link === mp3Url ? 'icon-pause' : 'icon-play'"
            @click="playMusic(scope.row)"
          >
          </i>
        </div>
      </el-table-column>
      
      <el-table-column
        min-width="15%"
        :label="$t('h.song.more')"
        align="center"
      >
        <div slot-scope="scope">
          <a :title="$t('h.tool.check')" @click="showDialog(scope.row)" class="el-icon-search check-btn mr5"> </a>
          <a v-if="$store.state.user.country != 'China'" :title="$t('h.tool.openDSPReport')" @click="openReport(scope.row,'d')" class="el-icon-s-order check-btn mr5"> </a>
          <a v-if="$store.state.user.country != 'China'" :title="$t('h.tool.openArtistReport')" @click="openReport(scope.row,'a')" class="el-icon-notebook-2 check-btn mr5"> </a>
        </div>
      </el-table-column>
    </el-table>

    <div class="page-bottom">
      <audio-player 
        class="audio-player" 
        :class="dialogTableVisible || showLyricDialog ? 'z-index10000' : ''" 
        ref="audioPlayer" 
        :mp3-name="mp3Name" 
        :mp3-url="mp3Url" 
        @updateAudioStatus="updateAudioStatus">
      </audio-player>

      <my-pagination
        :page-size="pageSize"
        :current-page="currentPage"
        :total="bondsAllList.length"
        @change-page="changePage"
      ></my-pagination>
    </div>

    <!-- 歌词展示 -->
    <el-dialog
      center
      width="40%"
      title="歌词"
      :visible.sync="showLyricDialog"
      :destroy-on-close="false"
    >
      <div id="lyric" class="lyric-box" v-html="songLyric"></div>
    </el-dialog>

    <!-- 导出文件前置条件 -->
    <el-dialog
      center
      width="25%"
      :title="$t('h.export.title')"
      :visible.sync="showPreCondition"
      :destroy-on-close="false"
    >
      <div class="pre-condition">
        <div  class="condition-item">
          <p>
            {{$t('h.singerPool.singerPool')}}
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content">{{$t('h.export.msg4')}}</div>
              <i class="icon-attention1"></i>
            </el-tooltip>
          </p>
          <el-select v-model="singerPool">
            <el-option v-for="singerPool in singerPoolList" 
            :key="singerPool.singer_pool_id" 
            :label="singerPool.singer_pool_name" 
            :value="singerPool.singer_pool_id">
            </el-option>
          </el-select>
        </div>
        <div v-if="$route.path.includes('demo') && exportType != 'excel'" class="condition-item">
          <p>
            {{$t('h.song.songPool')}}
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content">{{$t('h.export.msg5')}}</div>
              <i class="icon-attention1"></i>
            </el-tooltip>
          </p>
          <el-select v-model="songPool">
            <el-option v-for="songPool in songPoolList" 
            :key="songPool.song_pool_id" 
            :label="songPool.song_pool_name" 
            :value="songPool.id">
            </el-option>
          </el-select>        </div>
        <div class="upload-btn">
          <el-button @click="showPreCondition = false">{{$t('h.tool.cancel')}}</el-button>
          <el-button type="primary" @click="exportFile(exportType, false)">{{$t('h.tool.confirm')}}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</div>
  
</template>

<script>
import OverseasAnalyse from "./__more-overseas.vue"
import TrackAnalyse from "./__more.vue"
import createPdf from '@/lib/create-pdf'

export default {
  components: {
    OverseasAnalyse,
    TrackAnalyse
  },

  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: this.$t('h.song.aWeek'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t('h.song.aMonth'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t('h.song.threeMonth'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      currentPage: 1,
      pageSize: 8,
      bondsAllList: [],
      tempList: [],
      downloadIDList: [],
      downloadshow: false,
      dialogTableVisible: false,
      date: "",
      level: "",
      search_task_name: "",
      search_task_id: "",
      total: 0,
      is_mark: false,
      loading: false,
      AllList: "",
      dialogdata: { 
        id: '', 
        track_name: '',
        mp3Url: '',
        type: 'demo',
        multi_layer_result: [{
          level: '',
          score: ''
        }],
        permission:[]
      },
      modelList: {
        'na_pop_v1': 'North America',
        'na_pop_v2': 'North America',
        'na_edm_v1': 'EDM',
        'af_east_tz_v1': 'Tanzania',
        'af_west_ng_v1': 'Nigeria'
      },
      currentModelList: [],
      mp3Name: '',
      mp3Url: '',
      isAudioPlay: false,
      songLyric: '',
      showLyricDialog: false,
      showPreCondition: false,
      showModifyPermission:false,
      similarSongArr: [],
      singerPool: '',
      singerPoolList: [],
      songPool: '',
      songPoolList: [],
      exportType: '',
      permission:[{
          "playlist": 0,
          "audience_profile": 0, 
          "tiktok": 0 
        },{
          "playlist": 0,
          "audience_profile": 0, 
          "tiktok": 0 
      }],
      permissionName:''
    }
  },

  watch: {
    
    $route() {
      this.AllList = "";
      this.serch_task_name = "";
      this.serch_task_id = "";
      this.date = "";
      this.level = "";
      this.is_mark = false;

      this.taskDownload();
    },

    showLyricDialog (val) {
      if (!val) {
        const lyric = document.getElementById('lyric')
        lyric.scrollTop = 0
      }
    }
  },

  // 切换路由前，停止播放歌曲
  beforeRouteLeave (to, from, next) {
    this.$refs.audioPlayer && this.$refs.audioPlayer.pauseMusic()
    setTimeout(() => {
      next()
    });
  },

  mounted() {
    this.taskDownload()
  },

  computed: {
    scene_type() {
      if (this.$route.path.includes("demo")) {
        return "purchase";
      }
      else {
        return "evaluation";
      }
    },

    listenChange() {
      const { date, level, search_task_name } = this;
      return { date, level, search_task_name };
    },

    token: function () {
      return localStorage.getItem("token");
    }
  },

  methods: {
    // 打分异常行样式
    tableRowClassName({row}) {
      if (row.note) {
        return 'danger-row';
      }
      return '';
    },

    // 获取多平台评分信息
    getPlatformInfo (arr, type, platform) {
      const info = arr.find(item => item.layer_name === platform)
      if (info) {
        return info[type]
      } else {
        return ''
      }
    },
    getRegionPlatformInfo (arr, type, platform) {
      const info = arr.find(item => item.model_name === platform)
      if (info) {
        return info[type]
      } else {
        return ''
      }
    },
    showDialog(row) {
      this.dialogTableVisible = true;
      this.dialogdata = {
        id: row.song_id,
        track_name: row.song_name,
        mp3Url: row.file_link,
        artist_name:row.artist_name,
        region:row.artist_country,
        multi_layer_result: row.multi_layer_result,
        type: this.scene_type,
        permission:row.permission
      }
    },
    openReport(row,type){
      if (Object.values(row.permission).includes(1)){
        let host = window.location.host
        if (host === 'pdm.woa.com') {
          host = 'pdm.tencentmusic.com'
        }
        const url = `${window.location.protocol}//${host}${window.location.pathname}#/report-${type}?task_id=${row.task_id}&report_id=${row.song_id}`
        window.open(url)
      }else{
        this.$message({
          message: 'Please confirm report module(s) permission.',
          type: "error",
          offset: 120
        })
      }
      
    },
    changePage(page) {
      this.currentPage = +page;
      this.currentChangePage(this.bondsAllList, this.currentPage);
    },

    async taskDownload() {
      this.getTaskid()
      this.getSearchData()
      if (this.$route.params.ispush) {
        this.search_task_id = this.$route.params.id;
        this.search_task_name = this.$route.params.name;
        this.setSearchData()
        this.setTaskid()
        await this.getTaskList();
      }
      else if(this.search_task_id){
        await this.getTaskList();
      } 
      else {
        await this.searchList();
      }
      // 计算有多少个打分模型
      if (this.$store.state.user.country !== 'China') {
        const modelList = this.bondsAllList[0].multi_layer_result
        modelList.forEach(item => {
          if (this.modelList[item.model_name]) {
            this.currentModelList.push(item.model_name)
          }
        })
      }
    },

    downloadShow() {
      this.downloadshow = !this.downloadshow;
    },
    
    getRowKeys(row) {
      return row.song_id;
    },

    handleSelectionChange(rows) {
      this.downloadIDList = rows;
    },

    //全选下载
    toggleALLSelection() {
      this.bondsAllList.forEach((row) => {
        this.$refs.multipleTable.toggleRowSelection(row, true);
      });
    },

    //清空选择
    toggleSelection() {
      this.$refs.multipleTable.clearSelection();
    },

    playMusic(obj) {
      if (obj.file_link !== this.mp3Url) {
        this.mp3Name = obj.song_name
        this.mp3Url = obj.file_link
      } else {
        if (this.isAudioPlay) {
          this.$refs.audioPlayer && this.$refs.audioPlayer.pauseMusic()
        } else {
          this.$refs.audioPlayer && this.$refs.audioPlayer.playMusic()
        }
      }
    },

    stopAudio() {
      this.$refs.audioPlayer && this.$refs.audioPlayer.pauseMusic()
    },
    async getSongPools () {
      // 已有缓存数据无需往下调用接口
      if (this.songPoolList && this.songPoolList.length) return
      const res = await this.$store.dispatch('getSongPoolList', {
        token: this.token
      })
      if (res.success) {
        for (let i in res.result){
          if(res.result[i].id != '2'){
            this.songPoolList.push(res.result[i])
          }
        }
        this.songPool =  this.songPoolList[0].id
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 120
        })
      }
    },
    async getSingerPoolList () {
      // 已有缓存数据无需往下调用接口
      if (this.singerPoolList && this.singerPoolList.length) return

      const res = await this.$store.dispatch('getSingerPool', {
        token: this.token
      })
      if (res.success) {
        this.singerPoolList = res.singer_pools
        const douyin = this.singerPoolList.find(item => item.singer_pool_name === '抖音歌手池')
        this.singerPool = douyin ? douyin.singer_pool_id : this.singerPoolList[0].singer_pool_id
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 120
        })
      }
    },
    async exportFile (type, flag) {
      // 判断是否选择歌曲
      if (this.downloadIDList.length == 0) {
        this.$message({
          message: this.$t('h.export.msg1'),
          type: "normal",
          offset: 120,
        });
        return;
      }

      // 查询歌手池列表
      await this.getSingerPoolList()
      // 查询歌曲池列表
      await this.getSongPools()
      if (flag) {
        this.showPreCondition = true
        this.exportType = type
      } else {
        if (type === 'excel') {
          this.exportExcel()
        } else {
          if (this.downloadIDList.length > 10)(
          this.$message({
          message: this.$t('h.export.msg2'),
          type: "warning",
          offset: 120
          })
          )
          else{
            this.exportPdf()
          }
        }
      }
    },

    // 导出歌曲excel
    async exportExcel() {
      this.loading = true
      this.showPreCondition = false
      const song_id_list =[]
      this.downloadIDList.forEach((row) => {
        song_id_list.push(row.song_id)
      })
      const params = {
        token: this.token,
        song_id_list,
        models: [
          {
            model_name: "singer_match",
            model_details: {
              singer_pool_id: this.singerPool
            }
          }
        ]
      }
      const result = await this.$store.dispatch("exportExcel", params)
      if (result.code == 2000) {
        this.loading = false
        window.open(result.url)
        this.toggleSelection()
      } else if (result.code == 2001) {
        // 模型推理中，轮询结果
        setTimeout(() => {
          this.exportExcel()
        }, 3000)
      } else {
        this.loading = false
        this.$message({
          message: this.$t('h.export.msg3'),
          type: "error",
          offset: 120
        })
      }
    },

    async getSimilarHotSong (id) {
      this.loading = true
      const res = await this.$store.dispatch('getSimilarHotSong', {
        token: this.token,
        song_id: id,
        song_pool_id: 1
      })
      if (res.success) {
        let hotSongList = res.result.simi_songs_result || []
        hotSongList = hotSongList.filter(item => item.score >= 0.8)
        // 歌曲相似度保留两位小数
        hotSongList.forEach(item => {
          item.score = this.$util.round(item.score * 100, 0) + '%'
        })
        this.similarSongArr.push({
          song_id: +res.result.song_id,
          similar_songs: hotSongList
        })
        this.loading = false
      } else if (res.code === 2001) {
        // 2001 表示任务执行中，未获取到数据
        setTimeout(() => {
          if (this.showDialog) {  
            this.getSimilarHotSong(id)
          }
        }, 3000)
      } else {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 120
        })
        this.loading = false
      }
    },

    generateSimilarSongs () {
      this.downloadIDList.forEach(row => {
        this.getSimilarHotSong(row.song_id)
      })
    },

    // 导出歌曲分析pdf
    async exportPdf() {
      this.loading = true
      this.showPreCondition = false
      const song_id_list =[]
      this.downloadIDList.forEach((row) => {
        song_id_list.push(row.song_id)
      })
      let models =  [
          {
            model_name: "singer_match",
            model_details: {
              singer_pool_id: this.singerPool
            }
          }]
      if(this.$route.path.includes('demo')){
        models.push(
          {
            model_name: "song_match", 
            model_details: {
              song_pool_id: this.songPool
            }
          }
        )
      }
      else if(this.$route.path.includes('assess')){
        models.push(
          {
            model_name: "song_promotion", }
        )
      }
      const params = {
        token: this.token,
        song_id_list,
        models:models
      }
      try {
        const d = await this.$store.dispatch("exportPDF", params)
        if (d.success) {
          const opt = this.handleData(d.result)
          await createPdf(opt)
          this.loading = false
        } else if (d.code === 2001) {
          // 模型推理中，轮询结果
          setTimeout(() => {
            this.exportPdf()
          }, 3000)
        } else {
          this.$message({
            message: this.$t('h.export.msg3'),
            type: "error",
            offset: 120,
          })
          this.loading = false
        }
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },

    handleAgeFields(arr) {
      const list = [];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].includes("-")) {
          list.push(arr[i].replace(/-/, "\n" + "/" + "\n"));
        } else {
          list.push(arr[i]);
        }
      }
      return list;
    },

    handleData (arr) {
      const list = []
      const type = this.$route.path.includes('demo') ? 'demo' : 'assess'
      arr.forEach(item => {
        let age = []
        let city = []
        const charts = item.audiences_analyse
        let ageFields = []
        for (let key in charts.age) {
          age.push(charts.age[key].toFixed(2))
          ageFields.push(key)
        }
        ageFields = this.handleAgeFields(ageFields)
        for (let i in charts.city) {
          city.push(charts.city[i].toFixed(2))
        }
        // 处理相似歌曲数据
        if(item.song_match.length){
          item.song_match = item.song_match.filter(item => item.score >= 0.8)
        }
        const scoreInfo = this.downloadIDList.find(row => +row.song_id === +item.song_id)
        list.push({
          type,
          songData: {
            track_name: item.song_name,
            song_id: item.song_id,
            multi_layer_result: scoreInfo ? scoreInfo.multi_layer_result : []
          },
          chartsData: {
            age,
            city,
            sex: charts.sex
          },
          tableData: item.singer_match,
          similarSongs: item.song_match,
          ageFields,
          songPromotion: item.song_promotion,
          version:item.song_promotion ? item.song_promotion.version :''
        })
      })
      return list
    },

    async changeMark(row) {
      const result = await this.$store.dispatch("markSong", {
        token: this.token,
        song_id: row.song_id,
        is_tab: !row.is_tab,
      })
      if (result.code == 2000) {
        row.is_tab = !row.is_tab;
      } else {
        this.$message({
          message: this.$t('h.song.tagFailed'),
          type: "error",
          offset: 120,
        });
      }
    },

    async getTaskList() {
      this.loading = true;
      const result = await this.$store.dispatch("getSongs", {
        token: this.token,
        task_id: this.search_task_id,
        task_name: "",
        scene_type: this.scene_type,
      })
      if (result.success) {
        this.bondsAllList = result.song_info_list;
        // this.total = result.song_number;
        this.currentPage = 1;
        this.currentChangePage(this.bondsAllList, this.currentPage);
      } else {
        this.$message({
          message: result.msg,
          type: "error"
        })
      }
      this.loading = false;
    },

    async searchList() {
      if (
        this.AllList &&
        !this.search_task_name &&
        !this.level &&
        !this.date &&
        !this.is_mark
      ) {
        this.bondsAllList = this.AllList;
        this.currentPage = 1;
        this.currentChangePage(this.bondsAllList, this.currentPage);
        return;
      }
      this.loading = true;
      let times = "";
      let is_tab = "";
      // let levels = "";
      if (this.date) {
        times = this.date[0] + "|" + this.date[1];
      }
      if (this.is_mark) {
        is_tab = 1;
      }
      const result = await this.$store.dispatch("getSongs", {
        token: this.token,
        task_id: "",
        task_name: this.search_task_name,
        scene_type: this.scene_type,
        level: this.level,
        create_time: times,
        tab: is_tab,
      });
      if (result.success) {
        this.bondsAllList = result.song_info_list
        // this.total = result.song_number;
        this.currentChangePage(this.bondsAllList, this.currentPage);
      } else {
        this.$message({
          message: result.msg,
          type: "error"
        })
      }
      this.loading = false
    },

    //分页方法
    currentChangePage(list, currentPage) {
      let from = (currentPage - 1) * this.pageSize;
      let to = currentPage * this.pageSize;
      this.tempList = [];
      for (; from < to; from++) {
        if (list[from]) {
          this.tempList.push(list[from]);
        }
      }
      if (this.tempList.length && !this.mp3Url) {
        this.mp3Name = this.tempList[0].song_name
        this.mp3Url = this.tempList[0].file_link
        setTimeout(() => {
          this.$refs.audioPlayer && this.$refs.audioPlayer.pauseMusic()
        }, 200)
      }
      
      // this.$nextTick(() => {
      //     this.$refs.multipleTable.doLayout();
      // });
    },

    updateAudioStatus (status) {
      this.isAudioPlay = status
      if (status) {
        // 先去掉弹框中的小播放器节点
        const el = document.getElementById('mini-player')
        if (el) {
          el.remove()
        }
      }
    },

    async showSongLyric (id) {
      const res = await this.$store.dispatch('getSongLyric', {
        song_id: id,
        token: this.token
      })
      if (res.success) {
        this.songLyric = res.result.song_lyric.replace(/,|\n/g, "<br>")
        this.showLyricDialog = true
      } else {
        this.$message({
          message: res.msg,
          type: "normal",
          offset: 120,
        })
      }
    },

    setSearchData(){
      let item = {
        search_task_name: this.search_task_name,
        level: this.level,
        date: this.date,
        is_mark: this.is_mark,
      }
      sessionStorage.setItem('song-list-search',JSON.stringify(item))
      sessionStorage.setItem('song-list-taskID','')

    },
    getSearchData(){
      let item = JSON.parse(sessionStorage.getItem('song-list-search'))
      console.log(item)
      if (item) {
        this.search_task_name = item.search_task_name
        this.level = item.level
        this.date = item.date
        this.is_mark = item.is_mark
      }
    },
    setTaskid(){
      sessionStorage.setItem('song-list-taskID',this.search_task_id)
    },
    getTaskid(){
      this.search_task_id = sessionStorage.getItem('song-list-taskID')
    }
  }
};
</script>

<style scoped lang="stylus">
.page-songlist {
  height: 100%;
}
.main-songlist{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  }
.main-top-text {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
}
.main-table{
  overflow-y: auto;
}
.cancelBtn, .downloadBtn ,.modifyBtn{
  width: 120px;
  height: 40px;
  border-radius: 30px;
  margin-left: 20px;
  cursor: pointer;
  text-align: center;
}
.modifyBtn{
  width: 170px;
}
.only-mark {
  font-size: 12px;
  padding-right: 5px;
  color: #606266;
}
audio {
  display: none;
}
.cancelBtn {
  border: 1px solid #ff8c36;
  color: #ff8c36;
}
.choseText {
  font-size: 22px;
  margin-left: 20px;
}
.main-download {
  background-color: #fff4eb;
  border-radius: 6px;
  height: 50px;
  line-height: 50px;
  margin: 15px 0;
}
.main-wipe-buttom {
  margin: 20px;
  cursor: pointer;
  color: #ff8c36;
}
.form-item {
  width: 120px;
}
.paginationClass {
  /* position: absolute; */
  float: right;
  margin-top: 15px;
  width: 400px;
  right: 80px;
}

.mark-icon {
  cursor: pointer;
  font-size: 16px;
}

.lyric {
  font-size: 16px;
  cursor: pointer;
  color: #c6c6c6;
  margin-left: 15px;
}

.mark-icon:hover, .lyric:hover {
  color: #ff8c36;
}

.player {
  color: #777777;
  cursor: pointer;
}
.check-btn {
  cursor: pointer;
  font-size: 18px;
  color: #ff8c36;
}

.page-bottom {
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  margin-top: 10px;
}

.audio-player {
  margin: 0 30px 0 125px;
}

.z-index10000 {
  z-index: 10000;
}

.more-choice {
  width: 100px;
  font-size: 12px;
  color: #ff8c36;
  margin-bottom: 14px;
  cursor: pointer;
}

.lyric-box {
  height: 55vh;
  overflow: auto;
  line-height: 24px;
  text-align: center;
}

.pre-condition {
  width: 100%;
  .condition-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    p {
      margin-right: 10px;
    }
  }

  .upload-btn {
    text-align: center;
  }
}
.permission-dialog{
  .text{
    width: 85%;
    margin: 0 auto;
    word-break: normal;
    line-height: 24px;
  }
  .form-box{
    width: 70%;
    margin: 20px auto;

    .el-form-item {
      margin: 0 0 0 0 !important;  
    }
  }
  .permission-name{ 
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .checkboxs{
    display: flex;
    flex-direction: column;
  }
  .upload-btn {
    text-align: center;
  }
  }

::v-deep .el-checkbox__inner {
  border-radius: 8px;
}
::v-deep .el-form-item__label {
  font-size: 12px;
}
::v-deep .el-form-item {
  margin-right: 30px;
}
.search-level ::v-deep .el-form-item__content {
  width: 150px;
}

::v-deep .el-table .danger-row {background-color: rgba(254,240,240,0.6);}
::v-deep .el-table th>.cell {word-break: keep-all;}
</style>